import $ from 'jquery'
const api=function(){
    function myApi(){

    }
    myApi.prototype.system=function(){
        let u = navigator.userAgent,
            app = navigator.appVersion;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
        var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isAndroid) {
            //这个是安卓操作系统
            console.log('and')
            return 'android'

        }else if (isIOS) {
            //这个是ios操作系统
            console.log('app')
            return 'ios'
        }
        return 'win'
    }
    myApi.prototype.video=function(){
        let timer=null;
        let video=document.getElementById('video1');
        let poster=document.getElementById('poster'); //用于视频初始化poster图片
        let canvas = document.getElementById("myCanvas");
        let context = canvas.getContext("2d");
        canvas.width = window.innerWidth;  //获取屏幕宽度作为canvas的宽度  这个设置的越大，画面越清晰（相当于绘制的图像大，然后被css缩小）
        canvas.height = window.innerWidth*0.56;
        context.drawImage(poster, 0, 0, canvas.width, canvas.height);//canvas上绘制postere图片 也可以点击播放后隐藏图片无需绘制
        function draw1() {//绘制视频
            video.play();
            timer = setInterval(function(){
                context.drawImage(video, 0, 0, canvas.width, canvas.height);//绘制视频
            },40);
        };
        let isplay=false;//视频播放状态
        $('#myCanvas').on('touchstart',function(e){
            e.preventDefault();
            console.log('点击',isplay,'isplay')
            if(isplay){
                isplay=false;
                video.pause();
                $('.palyChange').show();
                clearInterval(timer)
            }else{
                video.play();
                $('.palyChange').hide();
                isplay=true;
                draw1()
            }

        })
        $('.palyChange').click(function(){ //初始化暂停状态下 点击播放（开始绘制视频）
            video.play();
            $('.palyChange').hide();
            isplay=true;
            draw1();
        });
    }
    return new myApi();

}
export default api;