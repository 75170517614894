import React, { Component } from 'react';
import './index.scss'
import message from '../../assets/信息.png'
import right from '../../assets/右箭头.png'
import weChat from '../../assets/微信.png'
import close from '../../assets/关闭 (2).png'
import myPics from '../../js/picUrl'


class Concat extends Component {
    state={
        showSmall:true,
        WeChat:''
    }

    handleMouseEnter(e){
        e.preventDefault();
        console.log('进来热')
        this.setState({
            showSmall:false
        })
    }
    handleToLeave(){
        this.setState({
            showSmall:true,
            WeChat:''
        })
    }
    showWeChat(num){
        this.setState({
            WeChat:myPics.weChat[num]
        })
    }
    weChatHide(){
        this.setState({
            WeChat:''
        })
    }

    render(){
        return(

                <div className='Concat'>
                    {this.state.showSmall?
                        (<div className='ConcatSmall'  onClick={this.handleMouseEnter.bind(this)} >
                  <img src={message} className='ConcatMessageIcon'/>
                  <div className='ConcatWord'>在线客服</div>


              </div>):(<div className='ConcatBig'>
                            <div className='weChatCoverFa' style={{display:this.state.WeChat?'block':'none'}}>
                                <div className='weChatCover'> <img src={this.state.WeChat}/></div>
                                <img className='weChatClose' src={close} onClick={this.weChatHide.bind(this)}/>
                            </div>
                            <div className='ConcatHead'><img src={message} className='ConcatMessageIcon'/>
                                <span className='ConcatWord ConcatWordBig'>在线客服</span>
                                <img onClick={this.handleToLeave.bind(this)} src={right} className='ConcatMessageIcon course'/>

                                </div>
                            <div className='ConcatBody'>
                                <div className='soWeChat'>
                                    <div className='soWeKefu'>
                                    <span className='SoWeKeFuWord'>客服1</span>
                                    <div className='SoWeChatNow' onClick={this.showWeChat.bind(this,0)} ><img src={weChat}/>微信交谈</div>
                                    </div>
                                    <div className='soWeKefu'>
                                        <span className='SoWeKeFuWord'>客服2</span>
                                        <div className='SoWeChatNow' onClick={this.showWeChat.bind(this,1)} ><img src={weChat}/>微信交谈</div>
                                    </div>
                                </div>
                                <div className='gzhImgs'>
                                <img className='ConcatImg' src='//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155486367180317548.jpg'/>
                                </div>
                                <p className='ConcatBigPhone'>
                                    客户服务热线<br/>
                                    <span>14757969259</span><br/>
                                    周一至周日<br/> 8:30-18:00
                                </p>
                                <p className='messageToUs' onClick={this.props.toMessage}>给我们留言</p>
                            </div>


                        </div>)
                    }
          </div>
        )
    }
}
export default Concat