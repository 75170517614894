import React, { Component } from 'react';
//import {HashRouter,Route,Switch} from 'react-router-dom'
//import 'amfe-flexible'
import './App.css';
//import See from './lianliank/see'
//import Find from './component/find/index'
//import Temp from './component/temp/index'
import Home from './component/home/index'
//import Mobile from './component/mobile/index'
//import Copy from './component/copy/index'

class App extends Component {


  render() {
    return (

            <Home />

    );
  }
}

export default App;
