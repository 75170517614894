import zheng from '../assets/zheng.png'
import qi from '../assets/qi.png'
import bao from '../assets/bao.png'
import lou from '../assets/lou.png'
import wang from '../assets/wang.png'
import li from '../assets/li.png'
const myPics={
    first:['//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155488369889316936.jpg'],
    firstShort:['//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155488393014418204.jpg'],
    endBg:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155350386048610620.jpg',
    case:{
        realCase:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155436393685516802.jpg',
        weChat:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155436354940417636.jpg',
        duty:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155436363937618891.jpg',
        student:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155436368143016421.jpg',
        teamBuy:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155436371235714863.jpg',
        partTime:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155436374092211478.jpg',
        tMall:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155436395688916771.jpg'
    },
    caseForMobile:[{pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155436393685516802.jpg',title:'实体案例',show:false},
        {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155436354940417636.jpg',title:'实体案例',show:false},
        {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155436363937618891.jpg',title:'实体案例',show:false},
        {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155436368143016421.jpg',title:'实体案例',show:false},
        {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155436371235714863.jpg',title:'实体案例',show:false},
        {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155436374092211478.jpg',title:'实体案例',show:false},
        {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155436395688916771.jpg',title:'实体案例',show:false}],
    backData:[
        '//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155350664430010189.jpg'
    ],
    leader:[{show:false,pic:wang,name:'汪楚涵',content:['广州蔻辰集团股东','香港问叹集团股东','浙江必盛投资管理有限公司执行董事','浙江鲜导者餐饮管理有限公司CEO','【VT联盟】万人团队创始人','中国微商大学特聘导师','微营销新锐讲师团团长','LP领袖教练技术资深助教','【2016年度微商盛典】最佳领袖奖','2017年四大卫视专访微商榜样']},
        {show:false,pic:qi,name:'七爷',content:['多家上市公司新零售顾问','CCTV《光荣之路》专访嘉宾','明星舞编05CREW街舞工作室创始人','中国微商领袖、十大杰出青年','大学生创业导师','寿全斋品牌操盘手','创造了3小时成交2亿800万的行业记录','众明星力推的NEE品牌创始人','香港鸿隆集团董事长','七爷亚洲品牌管理有限公司董事长','领军百万微商成功创业']},
        {show:false,pic:lou,name:'楼荣辉',content:['中国微商创业大赛明星导师','中国大学生创业大赛特约讲师','微友公社嘉宾讲师','专业微商操盘手']},
        {show:false,pic:bao,name:'宝哥',content:['淘乐惠品牌招商负责人','微电商平台创始人','乐乐裤品牌创始人','微商品牌资源对接','微商十大品牌领军人物','浙江淘乐惠培训部负责人','浙江乐淘品牌管理有限公司股东','义乌市讫星网络科技有限公司董事长','原浙江惠玩科技有限公司董事长']},
        {show:false,pic:li,name:'李莹',content:['淘乐惠联合创始人','淘乐惠培训部总监']},
        {show:false,pic:zheng,name:'郑海东',content:['曾任职华硕电脑集团技术事业部15年','微软Surface技术部OEM产品经理5年','从事淘宝电商相关业务运营11年','淘乐惠APP运营总监']}],
    team:{
        activity:[{pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351534103912221.jpg',title:'',content:''},
            {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351542501612656.jpg',title:'',content:''},
            {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351546053012899.jpg',title:'',content:''},
            {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351548421918350.jpg',title:'',content:''},
            {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351550123716352.jpg',title:'',content:''},
            {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351552510411083.jpg',title:'',content:''},
            {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351555770611116.jpg',title:'',content:''},
            {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351557896012950.jpg',title:'',content:''}],
        logTeam:[],
        daLao:[{pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351566930215744.jpg',title:'',content:''},
            {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351570442411450.jpg',title:'',content:''},
            {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351571861016819.jpg',title:'',content:''},
            {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351573369811238.jpg',title:'',content:''},
            {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351574672213596.jpg',title:'',content:''},
            {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351576257711930.jpg',title:'',content:''},
            {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351577566717140.jpg',title:'',content:''},
            {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351579015718986.jpg',title:'',content:''}],
        agent:[{pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351581884812964.jpg',title:'',content:''},
            {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351583953411681.jpg',title:'',content:''},
            {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351585555717264.jpg',title:'',content:''},
            {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351587316410636.jpg',title:'',content:''},
            {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351588676914826.jpg',title:'',content:''},
            {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351590321715967.jpg',title:'',content:''},
            {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351591540717761.jpg',title:'',content:''},
            {pic:'//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155351593152316723.jpg',title:'',content:''}],
    },
    weChat:['//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155497111007317607.jpg','//tlhh5web.oss-cn-hangzhou.aliyuncs.com/home155497253434910987.jpg']
};
export default myPics;